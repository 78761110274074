import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

export class Currentuser {
  username: string;
  password: string;
  accessToken: string;
  instanceUrl: string;
  id: string;
  name: string;
}

export class Campaign {
  constructor(
    CampaignId: string,
    CampaignName: string,
  ) { }
}

export class Employee {
  constructor(
    EmployeeId: string,
    EmployeeName: string,
  ) { }
}


export class Company {
  constructor(
    CompanyId: string,
    CompanyName: string,
  ) { }
}

export class ChildCompany {
  constructor(
    ChildCompanyId: string,
    ChildCompanyName: string,
  ) { }
}

export class Contact {
  constructor(
    ContactId: string,
    ContactName: string,
  ) { }
}

export interface Contact {
  firstname: string;
  lastname: string;
  email: string;
  title: string;
}

export interface Engagements {
  engagements: Engagement[];
  accessToken: string;
  instanceUrl: string;
}

export interface Engagement {
  id: number;
  campaignId: string;
  contactId: string;
  accountId: string;
  notes: string;
  date: string;
  ownerId: string;
  activityType: string;
  firstname: string;
  lastname: string;
  email: string;
  title: string;
  contacts: Contact[];
  newuser: boolean;
  serverdate: string;
  children: Account[];
  childAccountId: string;
}

@Injectable()
export class DataService {
  BASE_URL = '';
  constructor(private http: HttpClient) { }

  getCampaignData(currentUser) {
    return this.http.post<Campaign[]>(this.BASE_URL + '/campaigns', currentUser);
  }
  getEmployeeData(currentUser) {
    return this.http.post<Employee[]>(this.BASE_URL + '/employees', currentUser);
  }

  getCompanyData(currentUser) {
    return this.http.post<Company[]>(this.BASE_URL + '/accounts', currentUser);
  }

  getContactData(id, currentUser) {
    return this.http.post<Contact[]>(this.BASE_URL + '/contacts/' + id, currentUser);
  }

  postData(engagements) {
    return this.http.post(this.BASE_URL + '/engagements', engagements);
  }

  getAuthenticated(currentuser) {
    return this.http.post(this.BASE_URL + '/login', currentuser);
  }

  getChildrenAccountData(id, currentUser) {
    return this.http.post<ChildCompany[]>(this.BASE_URL + '/children/' + id, currentUser);
  }

}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  currentUser;
  nextPage = false;
  previousPage = true;
  isCategory = false;
  isCompany = false;
  isCampaign = false;
  selectedCategory;
  selectedAssignee;
  selectedCompany;
  selectedCampaign;

  loginInvalid = false;
  loginError = false;

  engagements: Engagement[] = [];
  names: Employee[] = [];
  companies: Company[] = [];
  contacts: Contact[] = [];
  iabCampaigns: Campaign[] = [];

  href: string = '';

  constructor(private dataService: DataService,
    public datepipe: DatePipe, @Inject(DOCUMENT) private document: Document,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.href = this.document.location.href;
    this.currentUser = JSON.parse(localStorage.getItem(this.href + ' - ' + 'currentUser')) === null
      ? {} as Currentuser : JSON.parse(localStorage.getItem(this.href + ' - ' + 'currentUser'));
    this.loginInvalid = this.currentUser.accessToken === null ? false : true;
    if (this.loginInvalid) {
      this.dataService.getCampaignData(this.currentUser).subscribe(data => {
        this.selectedAssignee = this.currentUser.id;
        this.loadMetaData();
      }, error => {
        this.logout();
      });
    }
  }

  logout() {
    this.currentUser.accessToken = null;
    localStorage.setItem(this.href + ' - ' + 'currentUser', JSON.stringify(this.currentUser));
    this.loginInvalid = false;
  }

  loadMetaData() {
    this.dataService.getEmployeeData(this.currentUser).subscribe(res => {
      this.names = res;
      this.names = this.sortByProperty(this.names, 'EmployeeName');
      this.currentUser.name =
        this.names.find(employee => employee['EmployeeId'] == this.currentUser.id)['EmployeeName'];
      localStorage.setItem(this.href + ' - ' + 'currentUser', JSON.stringify(this.currentUser));
    }, error => {
      console.log(error);
    });

    this.dataService.getCampaignData(this.currentUser).subscribe(res => {
      this.iabCampaigns = res;
      this.iabCampaigns = this.sortByProperty(this.iabCampaigns, 'CampaignName');
    }, error => {
      console.log(error);
    });

    this.dataService.getCompanyData(this.currentUser).subscribe(res => {
      this.companies = res;
      this.companies = this.sortByProperty(this.companies, 'CompanyName');
    }, error => {
      console.log(error);
    });
  }

  getAutheticated() {
    this.dataService.getAuthenticated(this.currentUser).subscribe(data => {
      this.currentUser.username = null;
      this.currentUser.password = null;
      this.currentUser.accessToken = data['access_token'];
      this.currentUser.instanceUrl = data['instance_url'];
      this.currentUser.id = data['id'].split('/')[5];
      this.selectedAssignee = this.currentUser.id;
      this.loadMetaData();
      this.loginInvalid = true;
      localStorage.setItem(this.href + ' - ' + 'currentUser', JSON.stringify(this.currentUser));
      this.loginError = false;
    }, error => {
      this.loginError = true;
      console.log(error);
    })
  }

  getCampaignName(engagement) {
    const campaign = this.iabCampaigns.find(campaign => campaign['CampaignId'] == engagement.campaignId);
    return campaign !== undefined ? campaign['CampaignName'] : '';
  }

  getAccountName(engagement) {
    const company = this.companies.find(company => company['CompanyId'] == engagement.accountId);
    return company !== undefined ? company['CompanyName'] : '';
  }

  getContactName(engagement) {
    if (engagement['contacts']) {
      const eng = engagement.contacts.find(contact => contact['ContactId'] == engagement.contactId);
      if (eng) {
        return eng['ContactName'];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  sortByProperty(listToSort, sort) {
    return listToSort.sort((a, b) => a[sort].localeCompare(b[sort]));
  }

  getContactData(engagement, isCompany) {
    const companyCode = isCompany ? this.selectedCompany : engagement.accountId;
    this.dataService.getContactData(companyCode, this.currentUser).subscribe(res => {
      let newResponse = this.sortByProperty(res, 'ContactName');
      if (isCompany) {
        this.contacts = newResponse;
        this.engagements = [];
        this.addEngagement();
      } else {
        engagement.contacts = newResponse;
      }
    }, error => {
      console.log(error);
    });
  }

  getChildrenDataForCompany(parentId, engagement) {
    this.dataService.getChildrenAccountData(parentId, this.currentUser).subscribe(res => {
      let newResponse = this.sortByProperty(res, 'ChildCompanyName');
      engagement.children = newResponse;
    }, error => {
      console.log(error);
    });
  }


  nextPageAction() {
    this.nextPage = true;
    this.previousPage = false;
  }

  previousPageAction() {
    this.nextPage = false;
    this.previousPage = true;
    this.resetAfterSuccess();
  }

  delete(id) {
    const newItems = [];
    this.engagements.forEach(e => {
      if (e.id != id) {
        e.id = newItems.length + 1;
        newItems.push(e);
      }
    });
    this.engagements = newItems;
  }

  reset() {
    this.selectedCampaign = '';
    this.selectedCompany = '';
    this.engagements = [];
    const newEngagement = {} as Engagement;
    newEngagement.id = this.engagements.length + 1;
    this.engagements.push(newEngagement);
  }

  saveAllEngagements() {
    this.currentUser = JSON.parse(localStorage.getItem(this.href + ' - ' + 'currentUser')) === null
      ? {} as Currentuser : JSON.parse(localStorage.getItem(this.href + ' - ' + 'currentUser'));
    if (this.currentUser.accessToken === null) {
      this.dataService.getCampaignData(this.currentUser).subscribe(data => {
      }, error => {
        this.loginInvalid = false;
        this.logout();
      });
    } else {
      this.engagements.forEach(engagement => {
        if (this.selectedCategory === 'company') {
          engagement.accountId = this.selectedCompany;
        } else if (this.selectedCategory === 'campaign') {
          engagement.campaignId = this.selectedCampaign;
        }
        engagement.ownerId = this.selectedAssignee;
        engagement.date = this.datepipe.transform(engagement.serverdate, 'MM/dd/yyyy');
        if (engagement.newuser && engagement.newuser === true) {
          engagement.contactId = null;
          engagement.accountId = engagement.childAccountId === undefined ? engagement.accountId : engagement.childAccountId;
        }
      });

      if (this.validateEngagements()) {
        const engagement = {} as Engagements;
        engagement.accessToken = this.currentUser.accessToken;
        engagement.instanceUrl = this.currentUser.instanceUrl;
        engagement.engagements = this.engagements;
        this.dataService.postData(engagement).subscribe(data => {
          this.toastr.success('Success', 'Engagement(s) have been created successfully');
          this.resetAfterSuccess();
        }, error => {
          console.log(error);
        });
      } else {
        this.toastr.warning('Incomplete Engagement(s)', 'Please Select / Enter all the feilds');
      }
    }
  }

  validateEngagements() {
    const count = new Array<boolean>();
    this.engagements.forEach(engagement => {
      let isContact = engagement['contactId'] !== undefined && engagement['contactId'] !== null;
      let isNewContact = engagement['firstname'] !== undefined && engagement['firstname'] !== null
        && engagement['lastname'] !== undefined && engagement['lastname'] !== null &&
        engagement['email'] !== undefined && engagement['email'] !== null &&
        engagement['title'] !== undefined && engagement['title'] !== null;
      let isValid = engagement.accountId !== null
        && engagement.ownerId !== null && engagement.serverdate != null && (isContact || isNewContact);
      if (!isValid) {
        count.push(isValid);
      }
    });
    return count.length > 0 ? false : true;
  }

  resetAfterSuccess() {
    this.nextPage = false;
    this.previousPage = true;
    this.isCategory = false;
    this.isCompany = false;
    this.isCampaign = false;
    this.selectedCategory = '';
    this.selectedCompany = '';
    this.selectedCampaign = '';
    this.engagements = [];
  }

  addEngagement() {
    const newEngagement = {} as Engagement;
    newEngagement.id = this.engagements.length + 1;
    newEngagement.contacts = this.contacts;
    this.getChildrenDataForCompany(this.selectedCompany, newEngagement);
    this.engagements.push(newEngagement);
  }


  assigneeChange(e) {
    this.selectedAssignee = e.value;
    this.engagements = [];
  }

  categoryChange(e) {
    this.isCategory = true;
    this.selectedCategory = e.value;
    if (this.selectedCategory === 'day') {
      this.addEngagement();
    }
  }

  campaignChange(e) {
    this.selectedCampaign = e.value;
    this.isCompany = false;
    this.isCampaign = true;
    this.addEngagement();
  }

  companyChange(e) {
    this.selectedCompany = e.value;
    this.isCompany = true;
    this.isCampaign = false;
    this.getContactData(null, true);
  }

  companyChangeInEngagement(engagement, e) {
    this.getContactData(engagement, false);
    this.getChildrenDataForCompany(e.value, engagement);
  }

  contactChange(engagement, e) {
    engagement.newuser = false;
  }

}
