<app-loader></app-loader>
<mat-card *ngIf="currentUser.accessToken !== null && loginInvalid" style="height: 30px;">
  <mat-card-content>
    <div style="display: flex;flex-direction: row;">
      <h2>Welcome </h2>
      <h3 style="margin-left:20px;color: tomato;">{{currentUser.name}} !</h3>
      <button mat-raised-button class="button" (click)="logout()"
      style="margin-left: 70%;height: 35px;width: 100px;">Log Out</button>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="currentUser.accessToken === null && !loginInvalid" style="margin-top: 10%;">
  <mat-card-content>
    <div style="display: flex;flex-direction: column;margin-left: 30%;">

      <img src="assets\images\logo.png" style="height: 80px;
      margin-bottom: 2em;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      width: 300px">
      <mat-form-field class="full-width-input" style="width: 50%;">
        <input matInput placeholder="Salesforce Username" [(ngModel)]="currentUser.username" id="addusername" #addusername="ngModel"
          required>
        <mat-error>
          Please provide a valid username
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input" style="width: 50%;margin-top: 3%;margin-bottom: 2%;">
        <input matInput type="password" placeholder="Password" [(ngModel)]="currentUser.password" id="addpassword"
          #addpassword="ngModel" required>
        <mat-error>
          Please provide a valid password
        </mat-error>
      </mat-form-field>
      <button style="margin-left: 120px;" mat-raised-button class="button"
        [disabled]="addusername.invalid || addpassword.invalid" (click)="getAutheticated()">Login</button>
      <mat-error style="margin-left: 120px;margin-top:5%;font-weight: bold;" *ngIf="loginError">
        Invalid Username or Password!
      </mat-error>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="currentUser.accessToken !== null && loginInvalid" style="max-width: 90%;width: auto;font-size: 90%;
color: rgb(0, 0, 0);
font-family: Futura BT W02, sans-serif !important;
margin: 1rem auto;
padding: 0px;">
  <div *ngIf="previousPage" style="height: 100px;
  margin-bottom: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  margin-left: 50px;font-family: Futura BT W02, sans-serif !important;">
    <img src="assets\images\logo.png" height="100px">
    <div style="padding: 1em;display: flex;flex-direction: column;">
      <h3 style="margin-top: 0;
    font-size: 1.6em;
    font-weight: 700;
    color: #000;font-family: Futura BT W02, sans-serif !important">
        Member Engagement Entry
      </h3>

      <mat-form-field style="width: 35%;">
        <mat-label>Engagement Type</mat-label>
        <mat-select (selectionChange)="categoryChange($event)" [(ngModel)]="selectedCategory">
          <mat-option value="campaign">Entry By Campaign</mat-option>
          <mat-option value="company">Entry By Company</mat-option>
          <mat-option value="day">Enter My Day</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="button" *ngIf="isCategory" mat-raised-button color="warn" (click)="nextPageAction()"
        style="width: 10%;margin-top: 2%;">
        Next Page
      </button>
    </div>
  </div>

  <div *ngIf="nextPage" style="height: 100px;
  margin-bottom: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  margin-left: 43px;font-family: Futura BT W02, sans-serif !important;">
    <img src="assets\images\logo.png" height="100px">
    <div style="padding: 1em;">
      <h3 style="margin-top: 0;
    font-size: 1.6em;
    font-weight: 700;
    color: #000;font-family: Futura BT W02, sans-serif !important">
        Member Engagement Entry
      </h3>
    </div>

    <div style="display: flex;flex-direction: row;margin-left: 20px;">
      <mat-form-field *ngIf="selectedCategory === 'campaign'" style="margin-right: 20px;width: 35%;">
        <mat-label>IAB Campaign</mat-label>
        <mat-select (selectionChange)="companyChange($event)" [(ngModel)]="selectedCampaign">
          <mat-option *ngFor="let name of iabCampaigns" value="{{name.CampaignId}}">{{name.CampaignName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="selectedCategory === 'company'" style="width: 35%;margin-right: 20px;">
        <mat-label>Ultimate Parent / Corporate Entity</mat-label>
        <mat-select (selectionChange)="companyChange($event)" [(ngModel)]="selectedCompany">
          <mat-option *ngFor="let name of companies" value="{{name.CompanyId}}">{{name.CompanyName}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>



    <div *ngIf="isCampaign || isCompany || selectedCategory === 'day'"
      style="display: flex;flex-direction: column;margin-left: 20px;">
      <mat-accordion *ngFor="let engagement of engagements">
        <mat-expansion-panel [expanded]="true" style="margin-bottom: 5px;">
          <mat-expansion-panel-header>
            <button mat-button color="warn" (click)="delete(engagement.id)" style="width: 1%;margin-right: 1%;">
              <mat-icon>delete</mat-icon>
            </button>
            <mat-panel-title>
              {{engagement.id}})
              <label *ngIf="engagement.newuser"
                style="color: seagreen;font-weight: bold;margin-left: 10px;display: inline-block;text-align:left;width: 30%;">
                {{engagement.firstname}} {{engagement.lastname}}
              </label>
              <label *ngIf="!engagement.newuser"
                style="color: seagreen;font-weight: bold;margin-left: 10px;display: inline-block;text-align:left;width: 30%;">
                {{getContactName(engagement)}}
              </label>
              <label *ngIf="engagement.campaignId !== undefined"
                style="margin-top:5px;color: rgb(19, 176, 204);text-align: left;display: inline-block;text-align:left;width: 40%;">
                {{getCampaignName(engagement)}}
              </label>
              <label *ngIf="engagement.accountId !== undefined"
                style="margin-top:5px;margin-left:2%;color: rgb(252, 132, 132);text-align: left;display: inline-block;text-align:left;width: 30%;">
                {{getAccountName(engagement)}}
              </label>
            </mat-panel-title>
            <mat-panel-description>
              <label *ngIf="engagement.activityType !== undefined"
                style="margin-top:5px;margin-left:2%;color: tomato;text-align:left;">
                {{engagement.activityType | uppercase}}
              </label>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div style="margin-top: 5px;">

            <mat-form-field *ngIf="selectedCategory === 'company' || selectedCategory === 'day'"
              style="margin-right: 20px;width: 35%;">
              <mat-label>IAB Campaign</mat-label>
              <mat-select [(ngModel)]="engagement.campaignId">
                <mat-option *ngFor="let name of iabCampaigns" value="{{name.CampaignId}}">{{name.CampaignName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="selectedCategory === 'campaign' || selectedCategory === 'day'"
              style="width: 35%;margin-right: 20px;">
              <mat-label>Ultimate Parent / Corporate Entity</mat-label>
              <mat-select required (selectionChange)="companyChangeInEngagement(engagement, $event)" [(ngModel)]="engagement.accountId">
                <mat-option *ngFor="let name of companies" value="{{name.CompanyId}}">{{name.CompanyName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 20px;">
              <mat-label>Activity Type</mat-label>
              <mat-select required [(ngModel)]="engagement.activityType">
                <mat-option value="Video Call">Video Call</mat-option>
                <mat-option value="Voice Call">Voice Call</mat-option>
                <mat-option value="In-Person Meeting">In-Person Meeting</mat-option>
                <mat-option value="Publish">Publish</mat-option>
                <mat-option value="Training">Training</mat-option>
                <mat-option value="Performance">Performance</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 20px;">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="engagement.serverdate" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="width: 35%;margin-right: 20px;">
              <mat-label>Contacts</mat-label>
              <mat-select [(ngModel)]="engagement.contactId" required (selectionChange)="contactChange(engagement, $event)">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let name of engagement['contacts']" value="{{name.ContactId}}">{{name.ContactName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 35%;margin-right: 20px;">
              <mat-label>Notes</mat-label>
              <textarea matInput placeholder="Enter Notes" [(ngModel)]="engagement.notes"></textarea>
            </mat-form-field>

            <div *ngIf="engagement.contactId === undefined" style="display: flex;flex-direction: column;">
              <mat-icon>person</mat-icon>
              <label>Contact Not Found?</label>
              <mat-checkbox style="margin-top: 20px;" [(ngModel)]="engagement.newuser">
                Create a New Contact
              </mat-checkbox>
            </div>
            <div *ngIf="engagement.newuser && engagement.contactId === undefined" style="margin-bottom: 1%;">
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      New Contact Data
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div style="margin-top: 5px;">
                    <mat-form-field style="margin-right: 15px;width: 40%;">
                      <mat-label>First Name</mat-label>
                      <input matInput placeholder="First Name" [(ngModel)]="engagement.firstname" required>
                    </mat-form-field>

                    <mat-form-field style="margin-right: 15px;width: 40%;">
                      <mat-label>Last Name</mat-label>
                      <input matInput placeholder="Last Name" [(ngModel)]="engagement.lastname" required>
                    </mat-form-field>

                    <mat-form-field style="margin-right: 15px;width: 40%;">
                      <mat-label>Title</mat-label>
                      <input matInput placeholder="Title" [(ngModel)]="engagement.title" required>
                    </mat-form-field>

                    <mat-form-field style="margin-right: 15px;width: 40%;">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" [(ngModel)]="engagement.email" required>
                    </mat-form-field>

                    
                    <mat-form-field style="width: 35%;margin-right: 20px;">
                      <mat-label>Company</mat-label>
                      <mat-select [(ngModel)]="engagement.childAccountId">
                        <mat-option *ngFor="let name of engagement['children']" value="{{name.ChildCompanyId}}">{{name.ChildCompanyName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                </mat-expansion-panel>
              </mat-accordion>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
    <div *ngIf="isCampaign || isCompany || selectedCategory === 'day'"
      style="display: flex;flex-direction: row;margin-bottom: 10px;margin-top: 1%;">
      <button class="button" color="warn" (click)="addEngagement()" style="width: 15%;margin-left: 75%;">
        Add Another Entry
      </button>
    </div>
    <button class="button" color="warn" (click)="previousPageAction()" style="width: 11%;margin-left: 1%;">
      Previous Page
    </button>
    <button *ngIf="isCampaign || isCompany || selectedCategory === 'day'" class="button" color="warn" (click)="saveAllEngagements()" style="width: 11%;margin-left: 2%;">
      Submit
    </button>
  </div>
</div>
<router-outlet></router-outlet>